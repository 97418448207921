@use "../../../components/styles/variables.module" as *;

.sb-calendar-container {
    margin: 0 auto;
}

.fc-event:hover {
    color: #777 !important;
}

.fc-list-view {
    .fc-list-heading {
        .ui-widget-header {
            background: #e8e8e8;
        }
    }

    .fc-scroller {
        overflow-x: auto !important;
    }
}

.sb-calendar-button {
    &.notactive {
        background-color: #FFF !important;
        color: #999 !important;
    }

    &.active {
        background-color: #EEE;
    }

    &-dot {
        display: inline-block;
        vertical-align: middle;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: .5rem;
        border: 1px solid;
        text-align: center;
        line-height: 1.5rem;
        padding-left: 2px;
    }
}
.sb-calendar-block {
    display: block;
    position: relative;
    border: 0;
    background: #FFF;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    margin: 0 7px;
    overflow: hidden;
    padding: 20px 10px 10px 10px;
    .calendarList{

        ul {
            margin: 0px 0px 12px 0px;
            padding: 0;
            list-style-type: none;
            li {
                border-radius: 5px;
                button{
                    width: 100%;
                    text-align: left;
                }

            }
        }
    }
}
.calendarName{
    font-size: 1rem;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}


.calendarSelector{

    @media (min-width: $break-point-lg) {
        max-height: 700px;
    }
    @media (max-width: $break-point-md) {
        max-height: 613px;
    }

    @media (max-width: $break-point-sm) {
        max-height: 387px;
    }

    @media (max-width: $break-point-xs) {
        max-height: 121px;
    }
    margin-right: 5px;
    transition: width 0.5s;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
}
.calendar-modal {
    color: $gray;
    padding: 0;
    .block {
        padding: 4px;
        min-height: 70px;
        border-top: 1px solid $border-color;
        &:first-child {
            border-top: none;
        }
        .sub{
            padding-left: 15px;
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
                width: 0;
                margin-left: -4px;
            }
            span[class*="fa-"] {
                font-size: 26px;
                vertical-align: middle;
                margin-right: 30px;
            }
            .full-date {
                display: inline-block;
                vertical-align: middle;
                padding-top: 15px;
            }
            .btn {
                margin-left: 10px;
                margin-top:12px;
            }
        }
    }
    .description {
        padding: 15px;
        .meta {
            .hosted-by {
                padding-top: 14px;
                font-style: italic;
            }
        }
    }
}
.sb-calendar-margin{
    ul{
        li{
            button{
                margin:0.1em 0em;
            }
        }
    }
}

.event-block-calendar{
    float: right;
    width: 80%;
}

.rbc-off-range {
    color: inherit !important;
}
.rbc-toolbar-label {
    font-weight: bold;
    font-size: 22px;
}
