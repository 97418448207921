@use "../../../components/styles/variables.module" as *;

#loginMenuButton, #userMenuButton {
    border-radius: 20px;
}

#skiptocontent {
    a {
        padding: 6px;
        position: absolute;
        top: -60px;
        left: 0;
        color: white;
        border-right: 1px solid white;
        border-bottom: 1px solid white;
        border-bottom-right-radius: 8px;
        background: #AD141F;
        -webkit-transition: top 1s ease-out;
        transition: top 1s ease-out;
        z-index: 1000;

        &:focus, &:active {
            position: absolute;
            left: 0;
            top: 0;
            outline: 0;
            -webkit-transition: top .5s ease-in;
            transition: top .5s ease-in;
        }
    }
}


.sb-primary-nav {
    width: 100%;
    z-index: 670;
    position: relative;
}

.sb-navbar-background {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1),0 1px 0 0 rgba(0, 0, 0, 0.1);
}

.sb-navbar-primary-container {
    display: block;
    z-index: 680;
    height: $primary-nav-height;
    padding: 5px 5px 5px 10px;

    @media(max-width: $break-point-lg) {
        padding-bottom: 2px;
    }
}
.sb-navbar-secondary-container {
    display: block;
    z-index: 680;
    padding: 0.85em 0;
    height: inherit;
    text-align: right;
}

.sb-navbar-margin-top {
    padding: 0;
}

.sb-navbar-logo-smallwidth {
    position: relative;

    @media(max-width: $break-point-sm) {
        margin-top: 10px;
    }
}

#socialNavItems {
    display: inline-block;
}

#secondaryNavLarge, #socialNavItems, #loginUserMenuButtons {
    @media(max-width: $break-point-lg) {
        display: none;
    }
}

.sb-navbar-primary-element {

    &.sb-navbar-primary-element-logo {
        margin: 0;
    }

    .sb-navbar-title {
        font-size: 1.75em;
        font-weight: 700;

        @media (max-width: $break-point-sm) {
            font-size: 1em;
        }
    }

    .sb-navbar-logo {
        max-width: 65vw;
        height: 70px;
    }

    span.sb-navbar-social-media {
        font-size: 2rem;
    }
    span.sb-navbar-search-icon {
        font-size: 1.1em;
        margin-left: 0.5em;
    }

    .btn {
        margin: 0;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
    }
}
.sb-navbar-button-text {
    display: inline-block;
    font-size: 1.3em;
    line-height: 1em;
    padding: 0.555em 1em;
}
.sb-navbar-button-image {
    display: inline-block;
    height: 1.75em;
    width: 1.75em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6.5em;
    position: relative;
    top: 0.35em;
    left: 0.8em;
}

.sb-navbar-secondary-element {
    display: inline-block;
    margin: 0 1em;
}

.sb-navbar-link-text {
    margin-left: 3px;
    line-height: 50px;
    display: block;
}

.sb-navbar-table {
    display: table;
    width: 100%;
    height: 76px;

    .sb-navbar-row {
        display: table-row;
        width: 100%;

        .sb-navbar-cell {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }
    }
}

.sb-navbar-search-label {
    letter-spacing: -0.01em;
    margin-top: 1em;
    font-size: 1em;
    text-align: center;
}

.navigation-container {
    top: 0;
    z-index: 720;

    @media(min-width: $break-point-lg) {
        position: sticky;
    }
}

.metismenu::after {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.metismenu-icon {
    width: 2em !important;
}

.fa-empty-icon {
  width: 5px !important;
}

.metismenu > .metismenu-container > .metismenu-item > .metismenu-link {
    padding-left: 15px;
}

.metismenu-container.visible > .metismenu-item > .metismenu-link {
    padding-left: 60px;
}

.metismenu-container .metismenu-container .metismenu-container .metismenu-link {
    padding-left: 70px !important;
}
